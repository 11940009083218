import "./scss/main.scss";

import "@/fonts/open-sans-v17-latin-regular.woff2";
import "@/fonts/open-sans-v17-latin-regular.woff";

import "@/fonts/CeraRoundPro-Bold.woff2";
import "@/fonts/CeraRoundPro-Bold.woff";

import "~/font-awesome/fonts/fontawesome-webfont.woff2";
import "~/font-awesome/fonts/fontawesome-webfont.woff";

import "@/components/navigation";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
    once: true
});


const cookieBar = document.getElementById("cookie-bar");

document.addEventListener("DOMContentLoaded", function() {
    if (document.cookie.indexOf("bigtheta_cookies_accepted") === -1) {
        cookieBar.classList.add("show")
    }
});

window.acceptCookies = () => {
    cookieBar.classList.remove("show");
    document.cookie = "bigtheta_cookies_accepted=true;path=/;samesite=lax;max-age=31536000"
}

if (module.hot) {
    module.hot.accept();
}