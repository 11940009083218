const html = document.getElementsByTagName("html")[0];

document.querySelector(".burger").addEventListener("click", () => {
  html.classList.toggle("nav-open");
});

window.addEventListener("scroll", e => {
  if (window.pageYOffset > 90) {
    html.classList.add("scrolled");
  } else {
    html.classList.remove("scrolled");
  }
});
